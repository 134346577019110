import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Section from '../components/Section'
import SectionContent from '../components/Section/Content'
import { css } from 'linaria'

export default ({ data, location }) => (
  <Layout
    location={location}
    title="Privacy Policy of Bejamas"
    showCtaInHeader={true}
  >
    <Section>
      <SectionContent columns="1">
        <div
          dangerouslySetInnerHTML={{
            __html: data.iubendaDocument.privacyPolicy.content
          }}
        />
      </SectionContent>
    </Section>
  </Layout>
)

export const query = graphql`
  query PrivacyPolicyPageQuery {
    iubendaDocument {
      privacyPolicy {
        content
      }
    }
  }
`
